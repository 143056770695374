form {
  .form-group {
    label {
      font-size: 0.9rem;
      font-weight: 700;
    }

    input {
      background-color: $white;
      border: 1px solid $light-gray;
      font-size: 1.1rem;
      height: inherit;
      padding: 0.7rem 1rem;

      &::placeholder {
        color: $light-font-gray;
      }

      &:focus {
        border-color: $arbilex-blue;
      }
    }

    .required-symbol,
    .glyphicon-remove {
      display: none;
    }
  }
}
