.modal-container {
  .modal-dialog {
    width: 45rem;
    text-align: center;

    .modal-header {
      border-bottom: none;
      padding: 3rem 4rem 0;
    }

    .modal-title {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .modal-subtitle {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .modal-close-button {
      cursor: pointer;
      float: right;
      font-size: 1.8rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .modal-body{
      padding: 2rem;
    }
    .modal-inside-body {
      font-family: 'DM Sans', Helvetica, Arial, serif;
      font-size: 16px;
      padding: 1rem;
    }
    .arbilex-button {
      padding: 0.75rem 4rem;
    }

    .helper-text {
      color: $steel;
      cursor: pointer;
      font-size: 0.95rem;

      &.underline span {
        border-bottom: 1px solid $steel;
      }
    }

    button[type='submit'] {
      margin-top: 1rem;
    }
  }
}
