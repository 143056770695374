.best-viewing-experience-modal-container {
  .modal-dialog {
    height: 90vh;
    width: 90vw;

    .modal-content {
      border: 0;
      overflow-y: auto;
      margin: auto;
      height: 100%;
      width: 100%;
    }

    .modal-close-button {
      cursor: pointer;
      float: right;
      font-size: 1.8rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    .modal-body {
      height: 95%;
      width: 90%;
      margin: auto;
      text-align: center;
      position: relative;
      resize: vertical;
      padding: 0;

      .center-this {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        resize: vertical;
        width: 100%;
        padding: 2rem;

        .image-section-container {
          text-align: center;

          .best-viewing-experience-image {

          }
        }

        .section-title {
          @include fluid-type(font-size, 600px, 1440px, 14px, 24px);
          font-weight: 700;
          text-align: center;
        }

        .section-body {
          padding-bottom: 3rem;
          text-align: center;
          @include fluid-type(font-size, 600px, 1440px, 10px, 18px);
        }

        .call-to-action {
          text-align: center;
          float: none;
          height: 46px;
          color: $white;
          font-size: 1em;
          border-radius: 7px;
          border-style: none;
          width: 220px;
          background-color: $arbilex-blue;
          margin-top: 2rem;
          &:focus {
            outline: 0;
          }
        }

        div {
          margin-bottom: 1rem;

          &.double-margin-bottom {
            margin-bottom: 2rem;
          }
        }

        ol {
          margin-bottom: 0;
          margin-left: 3rem;

          li {
            margin-bottom: 1rem;
            padding-left: 1rem;

            div {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
}
