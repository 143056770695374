.search-bar-container {
  .form-group {
    margin-bottom: 0;

    .input-group {
      background-color: $searchbar-background;
      border: 2px solid $searchbar-border;
      box-shadow: inset 0px 0px 5px $searchbar-background !important;
      margin-bottom: 0;
      border-radius: 5px;


      input {
        background-color: $searchbar-background;
        border: none !important;
        box-shadow: none !important;
        font-size: 1.1rem;
        width: 85%;
        border-radius: 5px;

        &::placeholder {
          color: $searchbar-text;
        }
      }

      .input-group-addon {
        background-color: $searchbar-background;
        border: none;
        display: inline-block;
        fill: $black;
        color: $searchbar-text;
        font-size: 1.5rem;
        padding-top: 0.7rem;
        width: 15%;
        border-radius: 5px;
      }
    }
  }

  .placeholder {
    color: $searchbar-text;
    position: absolute;
    top: 26px;
    pointer-events: none;
  }
}

.ReactAutocomplete-input {
  &::placeholder{
    color: $relevant-case-placeholder-text !important;
  }
}
