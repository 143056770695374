.funding-request-modal-form {
  text-align: left;
  p {
    text-align: left;
    font-family: "DM Sans", Helvetica, Arial, serif;
    font-weight: 500;
  }
  p:empty {
    display: none;
  }
  select,
  select option {
    color: #1e2738;
  }

  select:invalid,
  select option[value=""] {
    color: #CACACA;
  }
  /*Added for browser compatibility*/
  [hidden] {
    display: none;
  }
}
.funding-request-submit-button {
  padding: .75rem 1rem;
  color: white;
  background-color: #2f5bea;
  cursor: pointer;
  border-radius: 5px;
}
