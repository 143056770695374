.navbar {
  background-color: $navbar-background;
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-top: none;
  box-shadow: 0px 0px 8px $light-gray;
  height: $navbar-height;
  margin-bottom: 0;
  z-index: $z-index-1;

  .container {
    height: 100%;
    width: 100%;
    padding: 0 5rem;

    .navbar-header {
      height: 100%;

      .logo {
        font-size: 24px;
        font-family: "DM Sans", Helvetica, Arial, serif;
        font-weight: 500;
        color: $arbilex-logo-blue;
        line-height: 1.3rem;
        display: inline-block;
        width: 13rem;

        .logoMark {
          height: 33px;
          display: inline-block;
        }

        .arbilex {
          font-family: "DM Sans", Helvetica, Arial, serif;
          font-weight: 500;
          font-size: 24px;
          color: $arbilex-logo-blue;
          text-align: left;
          vertical-align: middle;
          line-height: 1.3rem;
          display: inline-block;
          padding-left: 1rem;
        }
      }
    }

    .navbar-collapse {
      height: 100% !important;
    }

    ul.nav {
      height: 100%;

      & > li {
        font-size: 1rem;
        font-family: "DM Sans", Helvetica, Arial, serif;
        font-weight: 500;
        height: 100%;
        margin: 0 0.5rem;
        padding: 0 1rem;

        &:before {
          content: "";
          background: $navbar-active-not;
          width: 0;
          height: 4px;
          left: 0px;
          // right: 0px;
          margin: auto;
          position: absolute;
          top: 0;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
        }

        &.nav_element:hover {
          a {
            color: $arbitrator-navbar-text-selected;
          }
        }

        &.nav_element:hover:before {
          content: "";
          background: $navbar-active-not;
          width: 100%;
          height: 4px;
          left: 0px;
          // right: 0px;
          margin: auto;
          position: absolute;
          top: 0;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
          border-radius: 0px 0px 5px 5px;
        }

        &.selected:before {
          content: "";
          background: $arbilex-logo-blue;
          width: 100%;
          height: 4px;
          left: 0px;
          // right: 0px;
          margin: auto;
          position: absolute;
          top: 0;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
          border-radius: 0px 0px 5px 5px;
        }

        &.selected:hover {
          a {
            color: $arbilex-logo-blue;
          }
        }

        &.selected:hover:before {
          background: $arbilex-logo-blue;
        }

        &.selected {
          background: $claimant-blue-faded;
          a {
            color: $arbilex-logo-blue;
          }
        }

        & > a {
          color: $navbar-active-not;
          padding: 1.5rem 0;
        }
      }
    }
  }
}

.navbar-nav > li > a {
  padding: 0;
}

@media (max-height: 900px) {
  .navbar {
    height: $navbar-height-small;

    .container {
      .navbar-header {
        .logo {
          padding: 9px 15px;

          .logoMark {
          }
          .arbilex {
          }
        }
      }
    }
  }
  .navbar .container ul.nav > li > a {
    padding: 1rem 0;
  }
}
