.modal {
  z-index: $z-index-5000;
}

.terms-conditions-modal-container {
  .modal-dialog {
    height: 90vh;
    width: 90vw;

    .modal-content {
      border: 0;
      overflow-y: scroll;
      margin: auto;
      height: 100%;
      width: 100%;
    }

    .modal-close-button {
      cursor: pointer;
      float: right;
      font-size: 1.8rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    .modal-body {
      height: 95%;
      padding: 3rem 4rem;

      div {
        margin-bottom: 1rem;

        &.double-margin-bottom {
          margin-bottom: 2rem;
        }
      }

      .section-title {
        text-decoration: underline;
        font-weight: 700;
      }
      .section-body{
        padding-bottom: 3rem;
      }

      button {
        cursor: pointer;
        color: blue;
      }

      ol {
        margin-bottom: 0;
        margin-left: 3rem;

        li {
          margin-bottom: 1rem;
          padding-left: 1rem;

          div {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
