.policy-terms-container {
  display: flex;
  justify-content: center;
}
.modal-backdrop.fade {
  opacity: 0.5;
}

.modal.show {
  // display: flex !important;
  opacity: 1;

  // background-color: red;
  // padding: 0;
  // position: relative;
  #modal-container {
  }
  .modal-dialog {
    height: 90vh;
    width: 90vw;
    //   padding: 10vh;
    display: flex;
    transform: translate(0, 0);
    margin: 5vh auto;

    .modal-content {
      overflow-y: scroll;
      border: 1px solid rgba(0, 0, 0, 1);
      outline: 0;

      .modal-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
      }
    }
  }
}

//STYLES FOR DEMO REQUEST MODAL
.is_demo_mod {
  // background-color: rgba(0, 0, 0, 0.2);
  height: fit-content;
  #modal-container {
    width: 40rem;

    .modal-content {
      width: 100%;
      height: fit-content;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #1e2738;
      box-sizing: inherit;
      margin: 0;
      padding: 0;
      position: relative;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

      .modal-head-section {
        position: relative;

        padding: 3rem 4rem 0 4rem;
        .modal-close-button {
          position: absolute;
          right: 0;
          right: 1rem;
          top: 1rem;
          font-size: 1.8rem;
          cursor: pointer;
        }
        .header-title-modal {
          display: inline-block;
          font-size: 1.7rem;
          font-weight: 700;
          font-family: 'DM Sans', Helvetica, Arial, serif;
        }
      }
      .modal-body {
        line-height: 1.5;
        color: #1e2738;
        box-sizing: inherit;
        margin: 0;
        // outline: 1px solid #036cdb !important;
        position: relative;
        padding: 2rem 4rem;
        font-weight: 700;
        font-size: 1rem;

        .demo-req-modal-container {
          .client-req-demo-modal-form {
            // display: flex;
            // flex-direction: column;
            .demo-req-input-label {
              display: block;
              box-sizing: border-box;
              width: 100%;
              height: 2.786rem;
              margin: 0 0 1.14286rem;
              padding: 0.57143rem;
              border: 1px solid #cacaca;
              border-radius: 2px;
              // background-color: red;
              box-shadow: inset 0 1px 2px rgba(30, 39, 56, 0.1);
              font-family: inherit;
              font-size: 1.14286rem;
              font-weight: 400;
              line-height: 1.5;
              color: #1e2738;
              transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
              appearance: none;

              &::placeholder {
                color: #cacaca;
              }
            }
            // .demo-req-input-label::placeholder {
            //   color: red;
            // }

            .formik-error {
              color: red;
              margin-bottom: 14px;
              // width: fit-content;
              // height: fit-content;
              font-size: 12px;
            }
            .enter-your-name {
              display: flex;
              column-gap: 30px;
              justify-content: space-between;

              div {
                width: 100%;
              }
            }

            .arbilex-button {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 700px) and (max-width: 1100px) {
  .is_demo_mod {
    #modal-container {
      width: 60vw;

      .modal-content {
        .modal-body {
          .demo-req-modal-container {
            .client-req-demo-modal-form {
              .enter-your-name {
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 810px) {
  .is_demo_mod {
    #modal-container {
      .modal-content {
        .modal-head-section {
          .header-title-modal {
            @include fluid-type(font-size, 320px, 720px, 18px, 24px);
          }
        }
        .modal-body {
          .demo-req-modal-container {
            .client-req-demo-modal-form {
              .enter-your-name {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 699px) {
  .is_demo_mod {
    #modal-container {
      width: 90vw;

      .modal-content {
        @media (min-width: 320px) and (max-width: 500px) {
          .modal-head-section {
            padding: 3rem 2rem 0 2rem;
          }
          .modal-body {
            padding: 2rem;

            .demo-req-modal-container {
              .client-req-demo-modal-form {
                .enter-your-name {
                }
              }
            }
          }
        }
      }
    }
  }
}

// Styles for Best Viewing Experience Modal

.center-this-bve {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .best-viewing-exp-modal-title {
    font-weight: 700;
    padding: 0 2rem;
    text-align: center;
    margin-bottom: 14px;
    @include fluid-type(font-size, 320px, 1440px, 14px, 24px);
  }
  .best-viewing-exp-modal-body {
    padding: 0 2rem;
    text-align: center;
    margin-bottom: 42px;
    @include fluid-type(font-size, 320px, 1440px, 14px, 18px);
  }

  .bve-modal-btn {
    width: 220px;
    text-align: center;
    float: none;
    height: 46px;
    color: #fefefe;
    font-size: 1rem;
    // @include fluid-type(font-size, 320px, 1440px, 14px, 18px);
    border-radius: 7px;
    border-style: none;
    background-color: #2f5bea;
    margin-top: 2rem;
  }
}

:root {
  .Toastify__toast {
    border-radius: 5px;
    background: $arbilex-blue;
    color: #fff;
    // top: 60px;test
  }
}
