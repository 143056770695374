.appointment-selector-container {
  width: 540px;
  height: 30px;
  margin: 13px 0 13px 20px;
  float: right;
  position: relative;

  .appointment-selector-helper-text {
    text-align: right;
    padding: 0 15px 0 0;
    font-size: 12px;
    display: inline-block;
    cursor: default;
    color: $filter-helper-text;
    float: left;
    font-weight: 500;
  }

  .appointment-selector-options {
    width: 402px;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    border: 1px solid $filter-border;
    background-color: $filter-background;
    float: right;

    .appointment-selector-option {
      text-align: center;
      display: inline-block;
      line-height: 30px;
      width: 25%;
      height: 30px;
      padding: 0;
      background-color: $filter-background;
      position: relative;
      border-right: 1px solid $filter-border;
      font-weight: 700;

      &:nth-child(1) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:nth-child(4) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: 0px;
      }

      &.selected {
        background-color: $filter-background-selected;

        .appointment-selector-option-text {
          width: 100px;
          text-align: center;
          color: $filter-text-selected;
        }
      }

      .appointment-selector-option-text {
        width: 100px;
        text-align: center;
        color: $filter-text;
      }

      .appointment-selector-option-selected-highlight {
        width: 85%;
        height: 3px;
        bottom: 4px;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        transition: all 0.4s;
        border-radius: 2px;
      }
      .appointment-selector-option-non-selected-highlight {
        width: 10%;
        height: 3px;
        bottom: 4px;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        transition: all 0.4s;
      }
    }
  }
}

.appointment-selector-container.shorted {
  width: 402px;
}
