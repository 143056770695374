html,
body,
#root,
.app,
.main-app {
  height: 100%;
}

.page-container {
  height: $height-minus-navbar;
}

@media (max-height: 900px) {
  .page-container {
    height: $height-minus-navbar-small;
  }
}


// @import 'arbitrator-page/module';
@import 'auth/module';
@import 'generics/module';
@import 'index/module';
@import 'not-found/module';
@import 'upload/module';

