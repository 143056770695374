.not-found-main {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgb(99,180,224);

  .not-found-payload {
    position: relative;
    z-index: $z-index-200;
    color: white;
    font-family: DM Sans,Helvetica,Arial,serif;
    text-align: center;
    text-shadow: 2px 2px rgba(0,0,0,.2);
    padding: 3rem 5rem;
  }

  .not-found-background {
    position: absolute;
    bottom: 0px;
    width: 100%;
    -webkit-mask-image:-webkit-linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,1) 40%);
  }

  .not-found-arbilex-logo {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 200px;
  }

  .not-found-title {
    margin-top: 1rem;
    font-weight: 700;
  }

  .not-found-body {
    font-size: 16px;
    margin: auto;
    width: 440px;
    margin-bottom: 2rem;
    font-family: 'DM Sans', Helvetica, Arial, serif;
  }

  .not-found-button {
    float: none;
    height: 46px;
    font-size: 1em;
    border-radius: 7px;
    border-style: none;
    width: 200px;
    background-color: $arbilex-blue;
    margin: auto;
    font-family: 'DM Sans', Helvetica, Arial, serif;
    padding: 1rem 2rem;

    &:focus {
      outline: 0;
    }

    .not-found-button-link{
      .not-found-button-text {
        float: left;
        color: $white;
        font-size: 16px;
        line-height: 18px;
      }
      .not-found-button-arrow-container{
        float: right;
        width: 18px;
      }
    }
  }
}
