.loading-container {
  text-align: center;
  padding-top: 20%;

  .spinner {
    margin: 1rem auto;
  }

  .loading-msg {
    font-size: 1.3rem;
    text-align: center;
    width: 100%;
  }
}
