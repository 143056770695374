// use this class to help negate the problem with the child Row
// taking up more space than the parent due to negative margins
// from react-bootstrap Rows
.bootstrap-row-fix {
  & > .row {
    margin: 0;

    & > div:first-child {
      padding-left: 0;
    }

    & > div:last-child {
      padding-right: 0;
    }
  }
}

.flex-container {
  display: flex;
  flex-direction: column;

  &.flex-row {
    flex-direction: row;
  }

  .remaining-height-container {
    flex-grow: 99;
    flex-shrink: 99;
    min-height: 0;
  }
}

.flex-1 {
  flex: 1;
}

// for containers that need both .flex-container and .remaining-height-container
.nested-flex-container {
  display: flex;
  flex-direction: column;
  flex-grow: 99;
  flex-shrink: 99;
  min-height: 0;

  &.flex-row {
    flex-direction: row;
  }
}

.no-known-cases {
  color: $helper-text-gray;
  font-size: 2rem;
  padding: 0 3rem;
  position: relative;
  text-align: center;
  top: 35%;
  height: fit-content;
  width: 100%;
  font-weight: 700;
  font-family: 'DM Sans', Helvetica, Arial, serif;
  max-height: 200px;
}

.premium-only {
  color: $arbitrator-navbar-text-selected;
  font-size: 1.7rem;
  position: relative;
  text-align: center;
  top: 40%;
  padding: 4rem;
  width: 100%;
  height: fit-content;
  max-height: 200px;
}

.premium{
  color: $navbar-active-not;
}

.clickable {
  cursor: pointer;
}

.case-link {
  color: $arbilex-logo-blue;
  cursor: pointer;
  text-decoration: none;

  img {
    margin-left: 0.5rem;
  }
}

.title {
  color: $arbitrator-title;
}

.table-header {
}

.case-link:hover {
  color: $arbilex-logo-blue;
  text-decoration: underline;
}

.blue-link {
  color: $arbilex-blue;
  cursor: pointer;
}

.arbi-bold {
  color: #2b2b2b;
  font-weight: 700;
  font-family: 'DM Sans', Helvetica, Arial, serif;
}

.long-img {
  width: 26px !important;
  height: 17px !important;
}

.loading {
  cursor: wait;
}

.pos-relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.full-margin-top {
  margin-top: 1rem;
}

.full-margin-bottom {
  margin-bottom: 1rem;
}

.half-margin-bottom {
  margin-bottom: 0.5rem;
}

.no-margin {
  margin: 0;
}

.no-side-margins{
  margin-left: 0;
  margin-right: 0;
}

.full-padding-bottom {
  padding-bottom: 1rem;
}

.no-padding {
  padding: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
}

.vertical-align-helper{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

// overflow-y
.vertical-overflow {
  overflow-y: auto;
}

// .modal-dialog {
//   margin: 5vh auto;
// }

.copy-link {
  &:hover {
    text-decoration: underline;
  }
}

// Removing autofill Styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  background-color: inherit;
  transition: background-color 5000s ease-in-out 0s;
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// custom col width
.col-md-1half, .col-sm-2half, .col-sm-3half, .col-md-4half, .col-md-5half, .col-md-6half, .col-md-7half, .col-sm-8half, .col-sm-9half, .col-md-10half, .col-md-11half{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .col-md-1half, .col-sm-2half, .col-sm-3half, .col-md-4half, .col-md-5half, .col-md-6half, .col-md-7half, .col-sm-8half, .col-sm-9half, .col-md-10half, .col-md-11half{
        float: left;
    }
    .col-md-1half { width: 12.5%; float: left; } // = 8,3333 + 4,16667
    .col-md-2half { width: 20.83333%; float: left;} // = 16,6666 + 4,16667
    .col-md-3half { width: 29.16667%; float: left;} // = 25 + 4,16667
    .col-md-4half { width: 37.5%; float: left;} // = 33,3333 + 4,16667
    .col-md-5half { width: 45.83333%; float: left;} // = 41,6667 + 4,16667
    .col-md-6half { width: 54.16667%; float: left;} // = 50 + 4,16667
    .col-md-7half { width: 62.5%; float: left;} // = 58,3333 + 4,16667
    .col-md-8half { width: 70.83333%; float: left;} // = 66,6666 + 4,16667
    .col-md-9half { width: 79.16667%; float: left;} // = 75 + 4,16667
    .col-md-10half { width: 87.5%; float: left;} // = 83,3333 + 4,16667
    .col-md-11half { width: 95.8333%; float: left;} // = 91,6666 + 4,16667
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@mixin fluid-type-height($properties, $min-vh, $max-vh, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-height: $min-vh) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vh - #{$min-vh}) / #{strip-unit($max-vh - $min-vh)});
    }
  }

  @media screen and (min-height: $max-vh) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}
