@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap');
body {
  position: relative;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
}
.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

// header
.header {
  display: flex;
  height: 6.429rem;
  position: fixed;
  z-index: $z-index-900;
  top: 0;
  width: 100%;
  font-weight: 500;
  // margin-top: 2.643rem;
  // padding: 0 4.286rem;

  .navbar-background {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 4.286rem 0 4.286rem;
    // height: 3.313rem;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: $white;

    &.have-scrolled {
      background-color: $white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
      color: black;

      .navbar-container {
        .logo {
          .logoMark {
            filter: brightness(0);
          }
        }
        .header-nav a .landing-header-solutions {
          &:hover {
          }
        }
        .nav-buttons .button-set-default {
          &.landing-action-request-demo {
            background-color: $landing-blue;
            border: 2px;
            border-color: $landing-blue;
            border-style: solid;
            color: $white;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            &:hover {
              background-color: rgba(47, 91, 234, 0.9);
            }
          }
          &:hover {
          }
        }
      }
    }

    .navbar-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      // padding: 0 25px;
      width: 100%;
      line-height: 1;
      box-sizing: border-box;
      background-color: transparent;
      // max-width: 1440px;
      // margin: 0 3.75rem;

      .logo {
        font-size: 36px;
        color: inherit;
        line-height: 2rem;
        display: inline-block;

        .logoMark {
          height: 3.571rem;
          display: inline-block;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }

        .arbilex {
          font-size: 36px;
          color: inherit;
          text-align: left;
          vertical-align: middle;
          line-height: 3.348rem;
          display: inline-block;
          padding-left: 1.5rem;
          font-weight: 500;
        }
      }

      .header-nav {
        display: flex;
        color: inherit;
        font-size: 14px;
        a {
          color: inherit;
          .landing-header-solutions {
            border-style: none;
            font-size: 14px;
            background: transparent;
            text-align: center;
            color: inherit;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            font-weight: 500;
            padding: 0.75rem;
            &:hover {
              color: $landing-blue;
            }
          }
        }
      }

      .nav-buttons {
        visibility: visible;
        display: flex;

        // a {
        text-decoration: none;
        .modal-element-click {
          display: flex;
          .landing-action-request-demo {
            padding: 6px 12px;
            text-decoration: none;
            border: 2px solid #fefefe;
            color: #fefefe;
            border-radius: 7px;
            cursor: pointer;
            line-height: 1.42857143;
            font-weight: 700;
            height: 46px;
            text-align: center;
            display: flex;
            align-items: center;
            width: auto;
            transition: all 0.2s ease-in-out;
            background-color: transparent;

            @include fluid-type(font-size, 320px, 1440px, 14px, 16px);

            &:hover {
              background-color: hsla(0, 0%, 97.6%, 0.5);
            }
          }
          .have-scrolled {
            background-color: #003ADB;
            border: 2px;
            border-color: #003ADB;
            border-style: solid;
            color: #fefefe;
            transition: all 0.2s;

            &:hover {
              // background-color: red;
              background-color: rgba(47, 91, 234, 0.9);
            }
          }
          // }
        }
        // #request-demo-nav {
        //   background-color: #2f5bea;
        //   border: 2px;
        //   border-color: #2f5bea;
        //   border-style: solid;
        //   color: #fefefe;
        //   transition: all 0.2s;
        // }

        .button-set-default {
          height: 46px;
          color: inherit;
          background: transparent;
          font-size: 16px;
          border-radius: 7px;
          border-style: none;
          font-weight: 700;

          &.landing-sign-in {
            font-family: 'DM Sans', Helvetica, Arial, serif;
            line-height: 1.139rem;
            width: auto;
            background-color: rgba(249, 249, 249, 0);
            a {
              color: inherit;
              text-decoration: none;
            }
            &:hover {
              color: $landing-blue;
            }
          }

          &.landing-action-request-demo {
            width: auto;
            background-color: rgba(249, 249, 249, 0);
            border: 2px;
            border-color: $white;
            border-style: solid;
            color: $white;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: rgba(249, 249, 249, 0.5);
            }
          }

          &:focus {
            outline: 0;
            border-color: rgb(204, 204, 204);
          }
        }

        .hamburger-container {
          color: inherit;
          background: transparent;
          border: 0;

          &:focus {
            outline: 0;
          }

          .hamburger {
            .hamburger-slice {
              width: 20px;
              height: 2px;
              background-color: $white;
              margin-bottom: 5px;
              transition: all 300ms ease-in-out;

              &:last-child {
                margin-bottom: 0;
              }

              &.selected {
                &:first-child {
                  transform: translate(2px) rotate(45deg) scale(1.05);
                  transform-origin: left top;
                }

                &:nth-child(2) {
                  opacity: 0;
                }

                &:last-child {
                  transform: translate(2px) rotate(-45deg) scale(1.05);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Hero
.landing-section.landing-top {
  background: linear-gradient(rgba(1, 8, 31, 0.5), rgba(1, 8, 31, 0.7)),
    url('/src/assets/landing/ArbiLex-V2.png') no-repeat center;
  background-size: cover;
  // background-attachment: fixed;
  font-size: 1rem;
  color: #f2f3f4;

  .text-section {
    margin: 0 auto 0 auto;
    padding-top: 12.116rem;
    text-align: center;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
    // line-height: 120%;
    letter-spacing: 0.05rem;

    .landing-header {
      margin: 0 auto;
      line-height: 120%;
      font-size: 64px;
      font-weight: 700;
      font-family: 'DM Sans', Helvetica, Arial, serif;
      .landing-paragraph-text {
        margin: 0;
        margin-bottom: 7.786rem;
        line-height: 1.6;
      }
      span {
        font-weight: 400;
        font-size: 60px;
      }

      .data-driven-orange {
        font-weight: 700;
        font-size: 64px;
        color: #fab908;
      }
    }

    .landing-subheader {
      line-height: 120%;
      margin-top: 1.429rem;
      margin-bottom: 7.786rem;
      font-size: 64px;
      font-weight: 700;
      font-family: 'DM Sans', Helvetica, Arial, serif;
      // margin-bottom: 1rem;

      div {
        display: inline-block;
      }
    }

    .landing-numbers {
      display: flex;
      justify-content: center;
      padding: 0 19.643rem;
      width: 100%;
      height: fit-content;

      .mobile-numbers-container {
        display: none;
        max-width: 100%;
      }

      .landing-numbers-container {
        display: none;
        justify-content: space-between;
        align-items: center;
        width: 63.571rem;

        .top-left-sq {
          align-self: flex-start;
          height: 1.143rem;
          width: 1.143rem;
          border-left: #fab908 0.286rem solid;
          border-top: #fab908 0.286rem solid;
        }
        .bottom-right-sq {
          align-self: flex-end;
          height: 1.143rem;
          width: 1.143rem;
          border-right: #fab908 0.286rem solid;
          border-bottom: #fab908 0.286rem solid;
        }
        #numbers-container {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          width: calc(100%-32px);
          max-width: 850px;
          // width: 100%;
          height: calc(100% - 32px);

          .swiper {
            // width: 100%;
          }
          .numbers-subsection {
            width: 18.544rem;
            text-align: left;

            h3 {
              font-size: 1.714rem;
              font-weight: 700;
              line-height: 2.4rem;
              margin: 0;
            }
            p {
              font-size: 1.143rem;
              font-weight: 400;
              line-height: 1.488rem;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .scroll-down {
    bottom: 4vh;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 2;
    width: 100px;
    text-align: center;
    position: absolute;

    .scroll-to-product {
      text-decoration: none;

      .arrow-down-mobile {
        display: none;
      }

      .arrow-down {
        vertical-align: top;
        height: 53px;
        width: 11px;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: translate(0, 5px);
        }
      }

      .scroll-text {
        color: $white;
        margin-top: 10px;
      }
    }
  }
}

// What we offer
$offering-content-area-1240: 1200px;
$offering-content-area-1240-padding: 88px;

.landing-offerings {
  background-color: white;

  .landing-offerings-container{
    .landing-offerings-spacing-container{
      padding: 88px 0px;

      .landing-offerings-title {
        line-height: 24px;
        display: flex;
        width: $offering-content-area-1240;
        padding: 0px $offering-content-area-1240-padding;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto 48px auto;
      }
    }
  }
}

// Carousel styling
#landing-offerings-carousel{

  .landing-offerings-carousel-slide{
    display: flex;
    width: $offering-content-area-1240;
    padding: 0 0 0 $offering-content-area-1240-padding+16px;
    align-items: flex-start;
    gap: 48px;
    margin: auto;

    .landing-offerings-carousel-slide-left{
      display: flex;
      width: 576px;
      height: 664px;
      padding: 56px 0px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
  
      .landing-offerings-carousel-slide-left-body-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;
        align-self: stretch;

        .landing-offerings-carousel-slide-left-text-container{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          align-self: stretch;
    
          .landing-offerings-carousel-slide-left-title {
            color: #000;
            font-size: 48px;
            font-weight: 700;
            line-height: 60px;
          }
    
          .landing-offerings-carousel-slide-left-body {
            color: #000;
            font-size: 20px;
            line-height: 32px;
          }
        }
    
        .landing-offerings-carousel-slide-left-call-to-action {
          text-align: center;
          text-decoration: none;
          // width: 158px;
          height: 46px;
          padding: 0px 12px;
          color: #FFF;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          background-color: #003ADB;
          border: 2px;
          border-color: #003ADB;
          border-style: solid;
          border-radius: 7px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s ease-in-out;
        }

        .landing-offerings-carousel-slide-left-call-to-action:hover {
          background-color: rgba(47, 91, 234, 0.9);
        }
      }
    } 
  
    .landing-offerings-carousel-slide-right-image-container {
      object-fit: cover;
      width: 472px;
      align-self: stretch;
      border-radius: 20px;
    }
  } 
}

// Custom Pagination
.swiper-custom-numbers-and-pagination{
  display: flex;
  width: 368px;
  align-items: center;
  gap: 8px;

  .swiper-numbers {
    color: #000;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 56px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
  }
}

.swiper-custom-pagination-container {
  display: flex;
  height: 2px;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.swiper-pagination-bullet {
  flex: 1 0 0;
  align-self: stretch;
  background: #e6e6e6;
  // cursor: pointer;
  transition: all 2s;
}

.swiper-pagination-bullet-active {
  background: #003ADB;
  box-shadow: 0px 2px 14px 0px rgba(64, 140, 255, 0.24);
}

// Who we are
.landing-who-text-mobile {
  display: none;
}
.landing-who {
  .landing-who-container {
    background: url('/src/assets/landing/who.png') no-repeat center;
    background-size: cover;
    // padding-left: 13.429rem;

    .landing-section-title {
      margin-bottom: 3.429rem;
      line-height: 1.5;
    }

    .landing-who-content-area {
      padding-top: 90px;
      padding-bottom: 90px;
      max-width: 69.7%;
      width: 100%;
      margin: 0 auto;
      height: 100%;

      .landing-who-text-container {
        display: flex;
        flex-direction: column;
        // width: 100%;
        max-width: 58.643rem;

        .landing-who-body-container {
          background-color: $white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0 0.786rem 0 1.429rem;
          height: 100%;
          font-size: 21px;

          .landing-who-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 2.857rem 2.857rem 2.286rem 2.857rem;
            white-space: pre-line;
            // margin-bottom: 60px;
            color: $landing-body;

            .landing-who-text-top {
              margin-bottom: 2rem;
              line-height: 1.5;
            }

            .landing-who-text-bold {
              font-weight: 700;
              font-family: 'DM Sans', Helvetica, Arial, serif;
            }
            .landing-who-text-highlight {
              color: $landing-blue;
              font-weight: 700;
              position: relative;
              display: inline-block;
              z-index: 2;

              .fading-highlighting {
                content: '';
                position: absolute;
                width: 102%;
                background: $landing-highlight;
                left: -1px;
                bottom: 0;
                height: 0.6em;
                z-index: $z-index-neg-1;
                border: 0;

                &.for {
                  width: 120%;
                }

                &.left-short {
                  width: 110%;
                }

                &.left-long {
                  width: 104%;
                }
              }
            }
          }

          section {
            margin-bottom: 2rem;
          }

          .landing-who-btn-container {
            height: auto;
            margin-top: 2rem;
            margin-bottom: 1rem;
            line-height: 1.5;

            .landing-who-btn-pretext {
              font-size: 21px;
              margin-bottom: 1rem;
            }

            .landing-who-join-our-team {
              height: 3.214rem;
              border-radius: 0.5rem;
              padding: 1rem 1.4rem;
              background-color: $white;
              color: $landing-title;
              outline: none;
              border: 2px solid $landing-blue;
              font-size: 18px;
              font-weight: 500;
              cursor: pointer;
              margin-bottom: -1rem;
              -webkit-transition: all 0.2s ease-in-out;
              transition: all 0.2s ease-in-out;

              &:hover {
                background-color: $landing-blue;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

// News & Events // Media
.landing-media-tbody {
  border: 0;
}
.landing-media-table {
  border: none;
}
.pagination-container-centerer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.pagination-container {
  display: flex;
}
.news-button-holder {
  display: flex;
  justify-content: center;
  height: 50px;
  // width: 34rem;

  a {
    width: 30px;
    cursor: pointer;
  }

  .activePage {
    background-color: #003ADB;
    border: 1px solid #185bc3;
    color: $white;
  }
}

.pagination-arrow-svg {
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.pagination-arrow-svg.left {
  background-image: url('/src/assets/landing/chev-left.svg');
}
.pagination-arrow-svg.right {
  background-image: url('/src/assets/landing/chev-right.svg');
}

.pagination-div.disabled {
  a {
    border: 1px solid #b8b8b8;
    cursor: default;
  }
}
.pagination-div {
  border-radius: 100px;
  width: 3.429rem;
  height: 3.429rem;
  margin: 0 10px;

  .disabled {
    .pagination-arrow-svg {
      background-size: contain;
      background-repeat: no-repeat;
    }
    .pagination-arrow-svg.left {
      background-image: url('/src/assets/landing/chev-left-dis.svg');
    }
    .pagination-arrow-svg.right {
      background-image: url('/src/assets/landing/chev-right-dis.svg');
    }
  }

  a {
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    @include fluid-type(font-size, 320px, 1023px, 10px, 16px);
    font-weight: 700;
    line-height: 1.21;
    text-decoration: none;
    color: #2c2e30;
    border: 1px solid #2c2e30;
    border-radius: 100px;
    width: 3.429rem;
    height: 3.429rem;
  }
}
.landing-media {
  background-color: $white;

  .landing-media-container {
    padding-top: 90px;
    padding-bottom: 90px;
    max-width: 69.7%;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    line-height: 1.5;

    .landing-media-title {
      margin-bottom: 1rem;
    }

    .landing-media-table {
      display: flex;
      padding: 0 1.429rem 0 1.429rem;
      border-collapse: separate;
      border-spacing: 0 1rem;
      height: 55rem;

      .landing-media-table-body {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        border: 0;

        .mews-event-holder {
          display: flex;
          width: 100%;
        }
        .news-button-holder {
          justify-self: flex-end;
        }
        .landing-media-table-row {
          gap: 3.429rem;
          display: flex;
          flex-direction: column;
          border: 0;
          height: fit-content;
          vertical-align: middle;

          td {
            padding: 0;
          }

          .landing-media-table-text {
            .landing-media-item {
              .landing-media-item-date {
                font-weight: 500;
                font-size: 14px;
                color: $landing-body;
              }
              .landing-media-item-title {
                font-weight: 700;
                font-size: 24px;
                color: $landing-title;

                &.LLX {
                  padding-right: 3rem;
                }
              }
              .landing-media-item-from {
                font-weight: 500;
                font-size: 14px;
                color: $landing-body;
              }
            }
          }

          .landing-media-table-img {
            .landing-media-item-image {
              height: 150px;
              img {
                width: 250px;
                border: 2px solid white;
                outline: 2px solid $landing-blue;
              }
            }
          }
        }
      }
    }
  }
}

// Call to Action
.landing-action {
  padding-top: 4rem;
  padding-bottom: 4rem;

  .landing-action-btn-container {
    text-align: center;

    .landing-action-request-demo {
      border-radius: 0.438rem;
      font-size: 1rem;
      font-weight: 700;
      padding: 1rem 3rem;
      background-color: #2f5bea;
      color: $white;
      outline: none;
      font-size: 21px;
    }
  }
}

// Footer
.landing-footer {
  background-color: $footer-background-color;
  padding: 0 8rem;

  .landing-footer-top-section {
    padding: 6rem 0 5rem;

    .logo {
      .logoMark {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        height: 50px;
      }
      .logo-text {
        font-weight: 700;
        font-size: 36px;
        color: black;
        text-align: left;
        vertical-align: middle;
        line-height: 50px;
        display: inline-block;
        padding-left: 1rem;
      }
    }
  }

  .landing-footer-mid-section {
    display: -webkit-flex;
    display: flex;
    padding-bottom: 1rem;
    font-weight: 400;
    line-height: 1.5;

    .landing-footer-table-of-contents-container {
      width: 45%;
      margin-right: 5%;

      .landing-footer-table-of-contents-title {
      }

      .landing-footer-table-of-contents-content-container {
        width: 100%;

        .landing-footer-table-of-contents {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-align-items: flex-start;
          align-items: flex-start;

          .landing-footer-table-of-contents-link {
            color: black;
            font-size: 18px;
            width: 40%;
            margin-right: 10%;
            margin-bottom: 1rem;
            cursor: pointer;
          }
        }
      }
    }

    .landing-footer-support-container {
      width: 25%;

      .landing-footer-support-title {
      }
      .landing-footer-support {
        .landing-footer-support-link {
          font-size: 18px;
          margin-bottom: 1rem;

          .email {
            color: black;
          }
          .best-viewing-experience {
            color: black;
            cursor: pointer;
            text-align: left;
            margin-right: 1rem;
            line-height: 1.5;
            appearance: none;
            border: none;
            padding: 0;
            background: transparent;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .landing-footer-contact-us-container {
      width: 25%;

      .landing-footer-contact-us {
        .landing-footer-contact-us-link {
          font-size: 18px;
          margin-bottom: 1rem;
          span {
            button {
              line-height: 1.5;
              appearance: none;
              border: none;
              padding: 0;
              background: transparent;
            }
          }

          .email {
            color: black;
            font-style: italic;
          }
          .address {
            color: black;
          }
        }
      }
    }
  }

  .landing-footer-bottom-section {
    text-align: center;
    line-height: 1.5;

    .landing-footer-bottom-section-line {
      height: 2px;
      background-color: #d4d4d4;
      margin: auto;
      margin-bottom: 1.5rem;
    }

    .landing-footer-bottom-section-ArbiLex-social-container {
      margin-bottom: 10px;

      .landing-footer-bottom-section-ArbiLex-social {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        list-style: none;
        margin-left: 0;
        width: 20%;
        align-items: center;
        margin: auto;
        line-height: 1.6;
        padding: 0;

        .landing-footer-bottom-section-ArbiLex-social-item {
          margin-left: 1.5rem;

          a:hover g {
            fill: #2f5bea;
            transition: 0.3s;
          }

          svg {
            height: 24px;
            width: 24px;
          }

          &:nth-child(1) {
            margin-left: 0;
          }
        }
      }
    }
    .landing-footer-bottom-section-text {
      color: black;
      padding-bottom: 2rem;
      font-size: 16px;

      .copyright {
      }

      .acceptable-use-policy {
        cursor: pointer;
        color: black;
        font-weight: 500;
        appearance: none;
        border: none;
        padding: 0;
        background: transparent;

        &:hover {
          text-decoration: underline;
        }
      }

      .terms-condition {
        cursor: pointer;
        color: black;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.landing-section {
  height: 100vh;
  min-height: 768px;
  // height: 100%;
  position: relative;
  // min-width: 1020px;
  width: 100%;
  color: $landing-page-gray;
}

.landing-section-title {
  text-transform: uppercase;
  color: $landing-section-title;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  margin-bottom: 2rem;
}

.landing-footer-title {
  font-weight: 700;
  font-family: 'DM Sans', Helvetica, Arial, serif;
  color: #222222;
  font-size: 18px;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.landing-container {
  background-color: $landing-background;
  // background-color: $white;
  overflow-x: hidden;
}

.landing-request-demo {
  float: none;
  height: 46px;
  color: $white;
  font-size: 1em;
  border-radius: 7px;
  border-style: none;
  width: 180px;
  background-color: $arbilex-blue;
  &:focus {
    outline: 0;
  }
}

.landing-subheader-mobile {
  display: none;
  margin: 0 auto;
  font-weight: 700;
  font-family: 'DM Sans', Helvetica, Arial, serif;
}
