//ArbiLex Redesign Colors
// general colors
$white: #ffffff;
$landing-blue: #2f5bea;
$president-green: #25cc78;
$claimant-blue: #4880ff;
$respondent-red: #eb4f58;
$arbilex-yellow: #ffc400;
$arbilex-orange: #ff9a07;
$arbilex-jeans: #67a3c0;
$error-red: #ec0000;
$helper-text-gray: #d4d4d4;
$help-icon-background: #dfe5f9;
$dashboard-background: #f2f2f2;
$content-background: #ffffff;
$link-color: #15c;
$selection: #9966cc;
$faded-selection: #aa7fd4;
$dark-text-grey: #4a4a4a;

// Landing page
$footer-background-color: #f2f2f5;
$why-faded-background: rgba(47, 91, 234, 0.9);
$landing-background: #f7f7fa;
$landing-text: #2c2d30;

// Landing Text colors
$landing-section-title: #b8b8b8;
$landing-title: #2c2e30;
$landing-body: #54565a;
$landing-highlight: #ffe000;

$president-green-faded: rgba($president-green, 0.05);
$claimant-blue-faded: rgba($claimant-blue, 0.05);
$respondent-red-faded: rgba($respondent-red, 0.05);

//Global Navbar
$navbar-background: #ffffff;
$arbilex-logo-blue: #06539d;
$arbilex-logo-blue-alt: #032950;
$navbar-active-not: #94979b;
$navbar-active: #2c2e30;

//SearchBars
$searchbar-background: #ffffff;
$searchbar-border: #e6e6e6;
$searchbar-text: #94979b;
$relevant-case-placeholder-text: $dark-text-grey;

//Filter By role
$filter-helper-text: #cccccc;
$filter-background: #f2f2f2;
$filter-border: #e8e8e8;
$filter-text: #9b9b9b;
$filter-text-selected: #032950;
$filter-background-selected: #ffffff;

//Arbitrator
//Arbitrator Navbar
$arbitrator-navbar-text: #9b9b9b;
$arbitrator-navbar-text-selected: $dark-text-grey;
$arbitrator-navbar-background: #e6e6e6;
$arbitrator-navbar-background-selected: #ffffff;
$arbitrator-navbar-border: #d4d4d4;
$arbitrator-nonblended: #06539d;

//Sidebar
$sidebar-background: #ffffff;
$sidebar-border: #e6e6e6;
$sidebar-text: #94979b;
// $sidebar-selected-background: #e7ecff;
$sidebar-selected-background: $claimant-blue-faded;

//Arbitrator text
$arbitrator-title: $dark-text-grey;
$arbitrator-subtitle: #636363;
$arbitrator-body: #636363;

//Co-appointment charts
$arbitrator-helper-text: #d4d4d4;

//Quantum Outcomes
$outcomecharttitles: $dark-text-grey;
$outcomelegendpercent: #032950;
$outcomelegendtext: $dark-text-grey;
$outcometableborder: #e6e6e6;
$outcometabletext: $dark-text-grey;
$outcometablecasedot: #9b9b9b;

//Quantum Costs
$payyourownway: #67a3c0;
$costsfollowtheevent: #ff9a07;

// landing page
$arbilex-blue: #2f5bea;

// dropdown-menu
$logout: #c72e35;
$report-issue: #c72e35;
$general-item: #032950;

// Tooltips
$tooltip-text: #2b2b2b;
$tooltip-border-color: #b2b2b2;

// Old stuff that might need changing
$neutral-gray: #9da7bd;
$citedByColor: #980032;
$citesColor: #003bbb;

$light-gray: rgb(183, 190, 196);
$pale-gray: #fafafa;
$citation-text-gray: #7790a3;
$very-dark-gray: #1d2736;

$navbar-blue: #21385e;

$landing-page-gray: #4f4f4f;

// Foundation Remains
// Arb network role colors
$pres: #67a3c0;
$claimant: #003bbb;
$respondent: #980032;

$red: #f93039;
$arbilex-red: #fe1c60;

$gold: gold;

$green: #90b022;

$blue: #4a90e2;

$old-arbilex-blue: #003bbb;
$header-blue: #6e6ec3;

$light-blue: #f5f6fa;
$background-gray: #fafafa;
$border-gray: #e5e7f1;

$background-gray: #f5f5f5;
$dark-background-gray: #f0f0f0;
$tooltip-gray: #dbdfe9;
$light-font-gray: #cbd0d5;
$font-gray: #7790a3;
$nav-link-gray: #9a9eaf;
$cool-grey: #b5bbc4;
$steel: #88909a;
$scrollbar-track-gray: #e5e7f1;
$hr-gray: #edeff5;
$input-border-gray: #aaafc1;

$black: #1e2738;
$charcoal: #2c3243;

$landing-shadow: #dce0e6;
$landing-purple: #663881;
$landing-light-blue: #248eb9;
$landing-button-blue: #04072d;

.text-red {
  color: $red;
}

.text-green {
  color: $green;
}
