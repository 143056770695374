@mixin fractal-button-theme($color, $bg-color, $disabled-opacity: 0.5) {
  border-color: $color;
  background-color: $bg-color;
  color: $color !important;
  outline: none !important;

  &:hover,
  &:focus {
    background-color: scale-color($bg-color, $lightness: -14%);
  }

  &:disabled {
    opacity: $disabled-opacity !important;
  }
}
