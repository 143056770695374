.arbilex-dropzone-wrapper {
    width: 500px;
    margin: auto;
    padding-top: 50px;

    .arbilex-dropzone {
        padding: 20px 4px 20px 4px;
        min-height: 300px;
        background-color: white;
        border: 5px dashed #1C6EA4;
        border-radius: 38px;
        width: 100%;
        height: 100%;
        cursor: pointer; 

        .arbilex-dropzone-instructions {
            text-align: center;
            pointer-events: none;
        }

        .dz-preview {
            position: relative;
            padding: 4px;
            pointer-events: none;


            .dz-size, .dz-image {
                display: none;
            }

            .dz-filename {
                display: inline-block;
            }

            .dz-error-mark, .dz-success-mark {
                display: none;
                position: absolute;
                right: 4px;
                top: 0px;
                mask-size: cover;
                height: 20px;
                width: 20px;
                border-radius: 20px;
                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }

        .dz-preview.dz-error .dz-error-message {
            color: red;
            font-weight: 700;
        }

        .dz-preview.dz-success {
            .dz-success-mark {
                display: inline-block;
                background: green;
            }
        }

        .dz-preview.dz-error {
            .dz-error-mark {
                display: inline-block;
                background: red;
            }
        }
    }
}
