.react-selectize.root-node {
  width: 70%;

  .react-selectize-control {
    background-color: $white !important;
    background-image: none !important;
    border-color: $light-gray !important;
    border-radius: 2rem !important;
    box-shadow: 0px 3px 1px $pale-gray;
    padding: 1rem 1rem 1rem 1.5rem;

    .react-selectize-placeholder,
    .simple-value {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;

      span {
        height: 30px;
      }
    }

    input.resizable-input {
      font-size: 1.5rem !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .react-selectize-reset-button-container {
      display: none;
    }

    // update color of toggle button
    .react-selectize-toggle-button-container {
      background-color: $arbilex-blue;
      border-radius: 2rem;

      .react-selectize-toggle-button {
        path {
          fill: $white !important;
        }
      }
    }
  }
}

.dropdown-menu.default.react-selectize.simple-select {
  display: block !important;
  top: 0 !important;
}
