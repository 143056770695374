@import 'base/module';
@import 'browsers/module';
@import 'utils/module';
@import 'vendors/module';

@import 'components/module';
@import 'layout/module';
@import 'themes/module';
@import 'pages/module';

html {
  //   margin: 0;
  //   padding: 0;
  //   background: #f2f2f2;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #1e2738;
  font-size: 14px;
  // line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

p {
  line-height: 1.6;
}

// :root {
//   .Toastify__toast {
//     border-radius: 5px;
//     background: $arbilex-blue;
//     color: #fff;
//     // top: 60px;test
//   }
// }
// background-color: red;
// padding: 0;
// position: relative;

.modal-dialog {
  height: 90vh;
  width: 90vw;
  //   padding: 10vh;
  display: flex;
  transform: translate(0, 0);
  margin: 5vh auto;

  .modal-content {
    order: 0;
    overflow-y: scroll;
    margin: auto;
    height: 100%;
    width: 100%;

    .modal-close-button {
      cursor: pointer;
      float: right;
      font-size: 1.8rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;
      padding: 0 4rem 3rem 4rem;

      .modal-title-element {
        text-decoration: underline;
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
  }
}
