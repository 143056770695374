.cookie-consent-widget {
  z-index: $z-index-4000;
  text-align: center;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0px;
  padding: 8px 16px;

  .cookie-flexbox {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    .cookie-text {
      margin: 8px 0;
      width: 900px;
      text-align: left;

      .privacy-policy-link {
        cursor: pointer;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;

        &:hover {
          color: $landing-blue;
        }
      }
    }

    button {
      margin: 4px 16px;
      padding: 8px 16px;
      color: white;
      background-color: black;
      border: 2px white solid;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;

      &:hover {
        background-color: rgba(249, 249, 249, 0.5);
      }
    }
  }
}
