$navbar-height: 4.5rem;
$footer-height: 0rem;
$height-minus-navbar: calc(100% - #{$navbar-height} - #{$footer-height});
$arbitrator-analytics-top-section-height: 14vh;
$arbitrator-analytics-top-section-bottom-margin: 1vh;

// small
$navbar-height-small: 50px;
$height-minus-navbar-small:calc(100% - #{$navbar-height-small} - #{$footer-height});

// z-indexes
$z-index-neg-1000: -1000;
$z-index-neg-1: -1;
$z-index-0: 0;
$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-5: 5;
$z-index-10: 10;
$z-index-15: 15;
$z-index-200: 200;
$z-index-900: 900;
$z-index-1000: 1000;
$z-index-1001: 1001;
$z-index-2000: 2000;
$z-index-3000: 3000;
$z-index-4000: 4000;
$z-index-5000: 5000;
$z-index-6000: 6000;
$z-index-7000: 7000;
$z-index-8000: 8000;
$z-index-9000: 9000;
$z-index-10000: 10000;
$z-index-11000: 11000;
$z-index-12000: 12000;
$z-index-13000: 13000;
$z-index-14000: 14000;
$z-index-15000: 15000;
$z-index-16000: 16000;
$z-index-17000: 17000;
$z-index-18000: 18000;
$z-index-19000: 19000;
$z-index-20000: 20000;
$z-index-20001: 20001;
$z-index-21000: 21000;


.z-index-0-class {
    z-index: $z-index-0
}

.z-index-1-class {
    z-index: $z-index-1
}

.z-index-2-class {
    z-index: $z-index-2
}

.z-index-3-class {
    z-index: $z-index-3
}
.z-index-1000-class {
    z-index: $z-index-1000
}

.z-index-2000-class {
    z-index: $z-index-2000
}
