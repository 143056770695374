.auth-modal-container {
  .modal-dialog {
    width: 40rem;

    .modal-header {
      border-bottom: none;
      padding: 3rem 4rem 0;

      .modal-title {
        display: inline-block;
        font-size: 1.7rem;
        font-weight: 700;
        font-family: 'DM Sans', Helvetica, Arial, serif;
      }
    }

    .modal-close-button {
      cursor: pointer;
      float: right;
      font-size: 1.8rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    .modal-body {
      padding: 2rem 4rem 2rem;
      font-weight: 700;
      font-family: 'DM Sans', Helvetica, Arial, serif;
      font-size: 1rem;
    }

    .helper-text {
      color: $steel;
      cursor: pointer;
      font-size: 0.95rem;

      &.underline span {
        border-bottom: 1px solid $steel;
      }
    }

    button[type='submit'] {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 700px) {
  .auth-modal-container {
    .modal-dialog {
      width: 90vw;
      .modal-header {
        .modal-title {
          @include fluid-type(font-size, 340px, 720px, 18px, 24px);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .auth-modal-container {
    .modal-dialog {
      width: 90vw;
      .modal-header {
        padding: 3rem 2rem 0;
      }
      .modal-body {
        padding: 2rem;
      }
    }
  }
}

@media (min-width: 700px) and (max-width: 1100px) {
  .auth-modal-container {
    .modal-dialog {
      width: 60vw;
    }
  }
}
@media (max-width: 719px) {
  .auth-modal-container {
    .modal-dialog {
      .modal-body {
        form {
          .form-group {
            input {
              padding: 0.5rem;
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}
