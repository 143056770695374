.custom-tab-bar {
  .tab {
    border-bottom: 1px solid transparent;
    color: $arbitrator-navbar-text;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    margin-right: 2rem;
    padding-bottom: 0.5rem;

    &:hover {
      border-bottom: 1px solid $arbitrator-navbar-border;
    }

    &.selected {
      border-bottom: 1px solid $arbitrator-nonblended;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: $arbitrator-nonblended;
      cursor: not-allowed;
    }
  }

  &.small {
    .tab {
      font-size: 0.9rem;
      margin-right: 0.8rem;
    }
  }

  &.blended {
    .tab-container {
      border-right: 1px solid $arbitrator-navbar-border;

      &:nth-child(1) {
        border-top-left-radius: 5px;
      }

      &:nth-child(5) {
        border-top-right-radius: 5px;
        border-right: 0px;
      }

      .tab {
        height: 40px;
        line-height: 20px;
        background-color: $arbitrator-navbar-background;
        border-bottom: none !important;
        color: $arbitrator-navbar-text;
        margin: 0;
        padding: 10px 12px 10px 12px;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;

        &.selected {
          background-color: $arbitrator-navbar-background-selected;
          color: $arbitrator-navbar-text-selected;
        }
      }
    }

    &.caseviewer {
      .tab-container {
        &:nth-child(1) {
          border-top-left-radius: 5px;
        }

        &:nth-child(2) {
          border-top-right-radius: 5px;
          border-right: 0px;
        }
      }
    }

    &.small {
      .tab-container {
        .tab {
          padding: 0.8rem 1rem 0.5rem;
        }
      }
    }
  }
}

.custom-tab-bar-img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  vertical-align: top;
}

@media (min-width: 1300px) {
  .display-large-only {
    display: initial;
  }
  .display-medium-only {
    display: none;
  }
  .display-small-only {
    display: none;
  }
}

@media (min-width: 1100px) and (max-width: 1400px) {
  .display-large-only {
    display: none;
  }
  .display-medium-only {
    display: initial;
  }
  .display-small-only {
    display: none;
  }
}

@media (max-width: 1100px) {
  .display-large-only {
    display: none;
  }
  .display-medium-only {
    display: none;
  }
  .display-small-only {
    display: initial;
  }
}

// for when vertical hieght is small
@media (max-height: 768px) and (min-width: 1525px) {
  .display-large-only {
    display: initial;
  }
  .display-medium-only {
    display: none;
  }
  .display-small-only {
    display: none;
  }
}
@media (max-height: 768px) and (min-width: 1340px) and (max-width: 1525px) {
  .display-large-only {
    display: none;
  }
  .display-medium-only {
    display: initial;
  }
  .display-small-only {
    display: none;
  }
}
@media (max-height: 768px) and (max-width: 1340px) {
  .display-large-only {
    display: none;
  }
  .display-medium-only {
    display: none;
  }
  .display-small-only {
    display: initial;
  }
}
