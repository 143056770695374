.__react_component_tooltip {
  max-width: 15rem;
  pointer-events: auto !important;

  .tooltip-text {
    a {
      color: $white;

      &:hover {
        color: $arbilex-blue;
      }
    }
  }
}
