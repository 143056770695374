.sources-button {
  color: #003BBB;
  cursor: pointer;

  .sources-button-text {
    // border-bottom: 2px dotted #003BBB;
  }
}

.sources-popup {
  border-radius: 5px;
  background-color: white;
  padding: 4px;
  position: absolute;
  top: 3px;
  /* border: 1px grey solid; */
  z-index: $z-index-1000;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);

  .sources-popup-arrow{
    background-color: inherit;
    border: inherit;
    height: 10px;
    pointer-events: none;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 10px;
    margin-top: -8px;
    margin-left: 15px;
    border-right: 0;
    border-bottom: 0;
    z-index: $z-index-1001;
  }

  .sources-text {
    color: $sidebar-text;
    margin-bottom: 0px;
  }

  .sources-flexbox{
    display: flex;
    justify-content: space-around;
    align-items: center;

    .sources-link {
      .sources-link-image {
        margin: 4px;
      }
    }
  }
}
