.modal {
  z-index: $z-index-5000;
}

.data-table-modal-container {
  .modal-dialog {
    width: 90vw;

    .modal-content {
      border: 0;
      margin: auto;
      width: 100%;
    }

    .modal-close-button {
      cursor: pointer;
      float: right;
      font-size: 1.8rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    .modal-body {
      height: 95%;
      padding: 3rem 4rem;

      div {
        margin-bottom: 1rem;

        &.double-margin-bottom {
          margin-bottom: 2rem;
        }
      }

      .section-title {
        font-weight: 700;
        text-align: center;
        font-size: 24px;
      }

      .section-body {
        padding-bottom: 3rem;
        .data-wrapper {
          .data-table {
            border-collapse: separate;
            border-spacing: 4px;
            font-weight: normal;
            background-image: url('/src/assets/Modals/WaterMark.png');
            // src/assets/Modals/WaterMark.png

            .data-table-head {
              border: none;

              td {
                border: 1px solid #D8E1FF;
                background-color: #F3F6FF;
                font-weight: 700;
              }

              .table-title {
                text-align: center;
                font-weight: 700;
              }
            }
            .data-table-body {
              background: transparent;

              tr {
                background: transparent;
              }

              td {
                border: 1px solid #E0E0E1;
              }
            }
          }

          .table-description {
            text-align: center;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }
}
