.navbar-nav {
  > .dropdown {
    #nav-dropdown {
      margin-left: 1rem;
      color: $arbilex-logo-blue;

      .caret-wrapper {
        background-color: $dashboard-background;
        border-radius: 5rem;
        display: inline;
        margin-left: 0.5rem;
        padding: 0.2rem;
        transition: all 150ms linear;

        svg {
          font-size: 1.4rem;
          fill: $black;
          vertical-align: top !important;
        }
      }
    }

    &.open #nav-dropdown {
      background-color: transparent !important;
      color: $arbilex-logo-blue-alt;

      .caret-wrapper {
        background-color: $arbilex-logo-blue-alt;

        svg {
          fill: $white;
        }
      }
    }

    .dropdown-menu {
      border-color: $filter-border !important;
      min-width: 120px;
      position: absolute;
      right: 0;
      left: auto;
      font-weight: 500;
      font-family: "DM Sans", Helvetica, Arial, serif;

      & > li > a {
        color: $general-item;
        text-align: right;
      }
      .report-issue a {
        color: $report-issue;
      }

      .logout a {
        color: $logout;
        border-top: 1px solid $filter-border;
      }
    }
  }
}
