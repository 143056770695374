// ***************************
// Header
// ***************************
@media (max-width: 1023px) {
  .header {
    .header-nav {
      a {
        .landing-header-solutions {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 960px) {
  #landing-numbers {
    width: 100%;
    height: fit-content;
    padding: 0;

    #landing-numbers-container {
      width: 95%;
      height: fit-content;

      #numbers-container {
        width: calc(100% - 32px);
        .numbers-subsection {
          width: 95%;
          margin: 0 5px;
          h3 {
            @include fluid-type(font-size, 720px, 960px, 24px, 24px);
          }

          p {
            @include fluid-type(font-size, 720px, 960px, 14px, 14px);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    height: 60px;

    .navbar-background {
      height: 60px;
      padding: 0;

      .navbar-container {
        height: 60px;
        padding: 0;

        .logo {
          .logoMark {
            height: 30px;
            width: 32.5px;
            margin-left: 36px;
          }
          .arbilex {
            display: none;
          }
        }
        .nav-buttons {
          .button-set-default {
            height: 40px;
            @include fluid-type(font-size, 320px, 1440px, 14px, 16px);
          }
        }
      }
    }
  }
}
@media (max-width: 522px) {
  .header {
    .navbar-background {
      padding: 0;
      .navbar-container {
        .nav-buttons {
          .button-set-default {
            font-size: 12px;

            &.landing-action-request-demo {
              width: 126px;
            }
            &.landing-sign-in {
              width: 80px;
            }
          }
        }
      }
    }
  }
}

// ***************************
// Hero
// ***************************
@media (max-width: 1234px) {
  .solutions-content-container {
    div {
      span {
        max-width: 260px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .landing-section.landing-top {
    .text-section {
      .landing-header {
        margin: 0 auto;
        width: auto;
        @include fluid-type(font-size, 320px, 1100px, 24px, 56px);

        .landing-paragraph-text {
          * {
            @include fluid-type(font-size, 320px, 1100px, 26px, 56px);
          }
          .landing-other-text {
            @include fluid-type(font-size, 320px, 1100px, 26px, 56px);
          }
        }
      }

      .landing-subheader {
        margin: 0 auto;
        margin-bottom: 1rem;
        width: auto;
        @include fluid-type(font-size, 720px, 1100px, 26px, 56px);
      }
    }
  }
}

@media (min-width: 1024px) {
  #landing-numbers-container {
    display: flex;
    height: 8.786rem;
  }
  .tablet-numbers-container {
    display: none;
  }
}
@media (min-width: 720px) and (max-width: 1023px) {
  #landing-numbers {
    padding: 0;
    justify-content: center;
  }

  .tablet-numbers-container {
    display: flex;
    width: 650px;

    min-height: 5.214rem;
    @include fluid-type(font-size, 320px, 719px, 14px, 24px);

    .tablet-numbers {
      padding-top: 1.143rem;
      width: calc(100% - 32px);
      line-height: 1.116rem;

      .tablet-numbers-paragraph-holder {
        width: 95%;
        text-align: left;
        line-height: 1.488rem;
        @include fluid-type(font-size, 720px, 1023px, 16px, 16px);

        .tablet-numbers-title {
          font-size: 24px;
        }
      }
      div {
        margin: 0;
        p {
          font-weight: 700;
          margin-bottom: 14px;
        }
      }
    }
    .top-left-sq {
      align-self: flex-start;
      height: 1.143rem;
      width: 1.143rem;
      border-left: #fab908 0.143rem solid;
      border-top: #fab908 0.143rem solid;
    }
    .bottom-right-sq {
      align-self: flex-end;
      height: 1.143rem;
      width: 1.143rem;
      border-right: #fab908 0.143rem solid;
      border-bottom: #fab908 0.143rem solid;
    }
  }
}

@media (min-width: 720px) {
  .landing-paragraph-text {
    .hidden-break {
      display: inline-block;
    }
  }
}
@media (max-width: 719px) {
  .landing-section.landing-top {
    min-height: 540px;
    padding-left: 36px;
    padding-right: 36px;

    .text-section {
      width: 100%;
      margin: 0;
      text-align: left;
      line-height: 2.232rem;
      padding-top: 154px;

      #landing-paragraph-text {
        margin-bottom: 0;
        .hidden-break {
          display: none;
        }
      }

      #landing-numbers {
        justify-content: flex-start;

        .mobile-numbers-container {
          width: 365.4px;
          height: fit-content;
        }
        .tablet-numbers-container {
          display: none;
        }
        .landing-numbers-container {
          display: none;
        }
      }

      .mobile-numbers-paragraph-holder {
        @include fluid-type(font-size, 522px, 719px, 16px, 16px);
        @include fluid-type(font-size, 320px, 522px, 12px, 16px);
        line-height: 1.488rem;

        .mobile-numbers-title {
          @include fluid-type(font-size, 320px, 522px, 14px, 19px);
        }
      }

      @media (min-width: 522) {
        .landing-subheader-mobile {
          div {
            @include fluid-type(font-size, 500px, 720px, 30px, 35px);
          }
        }
      }
      @media (max-width: 522px) {
        width: 70vw;
      }

      .landing-header {
        width: auto;
        margin: 0 auto;
        height: fit-content;
      }

      .landing-numbers {
        padding: 0;
        margin-top: 60px;
        height: 5.214rem;

        // width: 100px;
        .mobile-numbers-container {
          display: flex;
          min-height: 5.214rem;
          @include fluid-type(font-size, 320px, 719px, 14px, 24px);

          .mobile-numbers {
            padding-top: 1.143rem;
            padding-bottom: 1.143rem;
            width: calc(100% - 32px);
            line-height: 1.116rem;

            div {
              margin: 0;
              p {
                font-weight: 700;
                margin-bottom: 14px;
              }
            }
          }

          .top-left-sq {
            align-self: flex-start;
            height: 1.143rem;
            width: 1.143rem;
            border-left: #fab908 0.143rem solid;
            border-top: #fab908 0.143rem solid;
          }
          .bottom-right-sq {
            align-self: flex-end;
            height: 1.143rem;
            width: 1.143rem;
            border-right: #fab908 0.143rem solid;
            border-bottom: #fab908 0.143rem solid;
          }
        }
      }

      .landing-subheader {
        width: auto;
        margin: 0 auto;
        @include fluid-type(font-size, 320px, 719px, 24px, 30px);
        margin-bottom: 1rem;
      }

      .landing-subheader-mobile {
        line-height: 2.232rem;
        width: auto;
        margin: 0 auto;
        @include fluid-type(font-size, 320px, 719px, 24px, 24px);

        span {
          color: #fab908;
        }
      }

      .mission {
        width: auto;
        @include fluid-type(font-size, 320px, 719px, 13px, 16px);
        margin: auto auto;
        width: 70vw;

        @media (max-width: 522px) {
          width: 70vw;
        }
      }
    }
    .scroll-down {
      .scroll-to-product {
        .arrow-down {
          display: none;
        }
        .arrow-down-mobile {
          display: inline-block;
          height: 15px;
          width: 30px;
        }
        .scroll-text {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 523px) {
  .text-section {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 522px) {
  .text-section {
    font-size: 24px;
    margin-left: 24px;

    .landing-paragraph-text {
      display: none;
    }
  }
  .landing-subheader {
    display: none;
  }
  .landing-subheader-mobile {
    display: inline-block;
  }
  .landing-section.landing-top {
    background-attachment: scroll;
  }

  // .text-section {
  //   .landing-header {
  //     width: auto;
  //     margin: 0 auto;
  //     @include fluid-type(font-size, 320px, 719px, 24px, 24px);

  //     p {
  //       @include fluid-type(font-size, 320px, 719px, 24px, 24px);
  //     }

  //     span {
  //       @include fluid-type(font-size, 320px, 719px, 24px, 24px);
  //     }

  //     .data-driven-orange {
  //       @include fluid-type(font-size, 320px, 719px, 24px, 24px);
  //     }
  //   }
  // }
}

// ***************************
// What We Offer 
// ***************************
@media (max-width: 1239px) {
  // @include fluid-type(font-size, 720px, 960px, 24px, 24px);
  .landing-offerings {
    .landing-offerings-container{
      .landing-offerings-spacing-container{
        @include fluid-type(padding-bottom, 984px, 1239px, 72px, 88px);
        @include fluid-type(padding-top, 984px, 1239px, 72px, 88px);

        .landing-offerings-title {
          @include fluid-type(width, 984px, 1239px, 936px, 1200px);
          @include fluid-type(padding-left, 984px, 1239px, 64px, 88px);
          @include fluid-type(padding-right, 984px, 1239px, 64px, 88px);
          @include fluid-type(margin-bottom, 984px, 1239px, 40px, 48px);  
        }
      }
    }
  }

  #landing-offerings-carousel{
    .landing-offerings-carousel-slide{ 
      @include fluid-type(width, 984px, 1239px, 936px, 1200px);
      @include fluid-type(padding-left, 984px, 1239px, 64px + 16px, 88px + 16px);
      @include fluid-type(gap, 984px, 1239px, 24px, 48px);

      .landing-offerings-carousel-slide-left{
        @include fluid-type(width, 984px, 1239px, 456px, 576px);
        @include fluid-type(height, 984px, 1239px, 480px, 664px);
        @include fluid-type(padding-bottom, 984px, 1239px, 40px, 56px);
        @include fluid-type(padding-top, 984px, 1239px, 40px, 56px);
      
        .landing-offerings-carousel-slide-left-body-container {
          @include fluid-type(gap, 984px, 1239px, 40px, 58px);

          .landing-offerings-carousel-slide-left-text-container{
            @include fluid-type(gap, 984px, 1239px, 24px, 32px);

            .landing-offerings-carousel-slide-left-title {
              @include fluid-type(font-size, 984px, 1239px, 36px, 48px);
              @include fluid-type(line-height, 984px, 1239px, 48px, 60px);
            }
      
            .landing-offerings-carousel-slide-left-body {
              @include fluid-type(font-size, 984px, 1239px, 16px, 20px);
              @include fluid-type(line-height, 984px, 1239px, 24px, 32px);
            }
          }

          .landing-offerings-carousel-slide-left-call-to-action {
            // @include fluid-type(width, 984px, 1239px, 136px, 158px);
            @include fluid-type(height, 984px, 1239px, 40px, 46px);
            @include fluid-type(font-size, 984px, 1239px, 16px, 18px);
            @include fluid-type(line-height, 984px, 1239px, 24px, 28px);
          }
        }
      }

      .landing-offerings-carousel-slide-right-image-container {
        @include fluid-type(width, 984px, 1239px, 376px, 472px);
      }
    }
  }

  .swiper-custom-numbers-and-pagination{
    @include fluid-type(width, 984px, 1239px, 296px, 368px);

    .swiper-numbers {
      @include fluid-type(font-size, 984px, 1239px, 28px, 32px);
    }
  }
}

@media (max-width: 984px) {
  $offering-content-area-984: 936px;
  $offering-content-area-984-padding: 64px;

  .landing-offerings {
    .landing-offerings-container{
      .landing-offerings-spacing-container{
        @include fluid-type(padding-bottom, 744px, 984px, 64px, 72px);
        @include fluid-type(padding-top, 744px, 984px, 64px, 72px);

        .landing-offerings-title {
          @include fluid-type(width, 744px, 984px, 696px, 936px);
          @include fluid-type(padding-left, 744px, 984px, 32px, 64px);
          @include fluid-type(padding-right, 744px, 984px, 32px, 64px);
          @include fluid-type(margin-bottom, 744px, 984px, 32px, 40px);  
        }
      }
    }
  }

  // Carousel styling
  #landing-offerings-carousel{
    .landing-offerings-carousel-slide{
      @include fluid-type(width, 744px, 984px, 696px, 936px);
      @include fluid-type(padding-left, 744px, 984px, 32px+16px, 64px+16px);
      gap: 24px;

      .landing-offerings-carousel-slide-left{
        @include fluid-type(width, 744px, 984px, 336px, 456px);
        @include fluid-type(height, 744px, 984px, 416px, 480px);
        @include fluid-type(padding-bottom, 744px, 984px, 32px, 40px);
        @include fluid-type(padding-top, 744px, 984px, 32px, 40px);
    
        .landing-offerings-carousel-slide-left-body-container {
          @include fluid-type(gap, 744px, 984px, 32px, 40px);  

          .landing-offerings-carousel-slide-left-text-container{
            gap: 24px;
      
            .landing-offerings-carousel-slide-left-title {
              @include fluid-type(font-size, 744px, 984px, 24px, 36px);  
              @include fluid-type(line-height, 744px, 984px, 32px, 48px);
            }
      
            .landing-offerings-carousel-slide-left-body {
              @include fluid-type(font-size, 744px, 984px, 14px, 16px);  
              @include fluid-type(line-height, 744px, 984px, 20px, 24px);
            }
          }

          .landing-offerings-carousel-slide-left-call-to-action {
            // width: 136px;
            @include fluid-type(height, 744px, 984px, 36px, 40px);
            @include fluid-type(font-size, 744px, 984px, 14px, 16px);  
            @include fluid-type(line-height, 744px, 984px, 20px, 24px);
          }
        }
      } 
    
      .landing-offerings-carousel-slide-right-image-container {
        @include fluid-type(width, 744px, 984px, 288px, 376px);
      }
    } 
  }

  // Custom Pagination
  .swiper-custom-numbers-and-pagination{
    @include fluid-type(width, 744px, 984px, 216px, 296px);

    .swiper-numbers {
      @include fluid-type(font-size, 744px, 984px, 20px, 28px); 
      @include fluid-type(width, 744px, 984px, 40px, 56px);
      @include fluid-type(height, 744px, 984px, 40px, 48px); 
    }
  }
}

@media (max-width:744px) {
  $offering-content-area-744: 696px;
  $offering-content-area-744-padding: 32px;

  .landing-offerings {
    .landing-offerings-container{
      .landing-offerings-spacing-container{
        @include fluid-type(padding-bottom, 464px, 744px, 48px, 64px);
        @include fluid-type(padding-top, 464px, 744px, 48px, 64px);

        .landing-offerings-title {
          @include fluid-type(width, 464px, 744px, 416px, 696px);
          @include fluid-type(padding-left, 464px, 744px, 0px, 32px);
          @include fluid-type(padding-right, 464px, 744px, 0px, 32px);
          @include fluid-type(margin-bottom, 464px, 744px, 24px, 32px);  
        }
      }
    }
  }

  // Carousel styling
  #landing-offerings-carousel{
    .landing-offerings-carousel-slide{
      @include fluid-type(width, 464px, 744px, 416px, 696px);
      @include fluid-type(padding-left, 464px, 744px, 0px + 16px, 32px + 16px);
      @include fluid-type(gap, 464px, 744px, 16px, 24px);  

      .landing-offerings-carousel-slide-left{
        @include fluid-type(width, 464px, 744px, 256px, 336px);
        @include fluid-type(height, 464px, 744px, 336px, 416px);
        padding: 32px 0px;
        min-width: 256px;
    
        .landing-offerings-carousel-slide-left-body-container {
          @include fluid-type(gap, 464px, 744px, 24px, 32px);  

          .landing-offerings-carousel-slide-left-text-container{
            @include fluid-type(gap, 464px, 744px, 16px, 24px);  
      
            .landing-offerings-carousel-slide-left-title {
              @include fluid-type(font-size, 464px, 744px, 20px, 24px);  
              @include fluid-type(line-height, 464px, 744px, 24px, 32px);  
            }
      
            .landing-offerings-carousel-slide-left-body {
              @include fluid-type(font-size, 464px, 744px, 12px, 14px);  
              @include fluid-type(line-height, 464px, 744px, 16px, 20px);  
            }
          }

          .landing-offerings-carousel-slide-left-call-to-action {
            // @include fluid-type(width, 464px, 744px, 112px, 136px);
            @include fluid-type(height, 464px, 744px, 32px, 36px);
            @include fluid-type(font-size, 464px, 744px, 12px, 14px);  
            line-height: 20px;
          }
        }
      } 
    
      .landing-offerings-carousel-slide-right-image-container {
        @include fluid-type(width, 464px, 744px, 200px, 288px);
      }
    } 
  }

  // Custom Pagination
  .swiper-custom-numbers-and-pagination{
    @include fluid-type(width, 464px, 744px, 184px, 216px);

    .swiper-numbers {
      @include fluid-type(font-size, 464px, 744px, 18px, 20px);  
      @include fluid-type(width, 464px, 744px, 32px, 40px);
      @include fluid-type(height, 464px, 744px, 32px, 40px);
    }
  }
}

@media (max-width:464px) {
  $offering-content-area-464: 416px;
  $offering-content-area-464-padding: 0px;

  .landing-offerings {
    .landing-offerings-container{
      .landing-offerings-spacing-container{
        @include fluid-type(padding-bottom, 320px, 464px, 24px, 48px);
        @include fluid-type(padding-top, 320px, 464px, 24px, 48px);

        .landing-offerings-title {
          @include fluid-type(width, 320px, 464px, 272px, 416px);
          padding: 0px;
          @include fluid-type(margin-bottom, 320px, 464px, 16px, 24px);  
        }
      }
    }
  }

  // Carousel styling
  #landing-offerings-carousel{
    .landing-offerings-carousel-slide{
      @include fluid-type(width, 320px, 464px, 272px, 416px);
      @include fluid-type(padding-left, 320px, 464px, 0px, 0px + 16px);
      gap: 16px; 

      .landing-offerings-carousel-slide-left{
        @include fluid-type(width, 320px, 464px, 200px, 256px);
        height: 336px;
        @include fluid-type(min-width, 320px, 464px, 200px, 256px);
        @include fluid-type(padding-bottom, 320px, 464px, 16px, 32px);
        @include fluid-type(padding-top, 320px, 464px, 16px, 32px);

    
        .landing-offerings-carousel-slide-left-body-container {
          @include fluid-type(gap, 320px, 464px, 24px, 32px);

          .landing-offerings-carousel-slide-left-text-container{
            gap: 16px;
      
            .landing-offerings-carousel-slide-left-title {
              @include fluid-type(font-size, 320px, 464px, 18px, 20px);
              @include fluid-type(line-height, 320px, 464px, 24px, 32px);
            }
      
            .landing-offerings-carousel-slide-left-body {
              @include fluid-type(font-size, 320px, 464px, 10px, 12px);
              line-height: 16px;
            }
          }

          .landing-offerings-carousel-slide-left-call-to-action {
            // @include fluid-type(width, 320px, 464px, 88px, 112px);
            @include fluid-type(height, 320px, 464px, 28px, 32px);
            @include fluid-type(font-size, 320px, 464px, 10px, 12px);
            @include fluid-type(line-height, 320px, 464px, 16px, 20px);
          }
        }
      } 
    
      .landing-offerings-carousel-slide-right-image-container {
        @include fluid-type(width, 320px, 464px, 128px, 152px);
        border-radius: 20px 0 0 20px;
      }
    } 
  }

  // Custom Pagination
  .swiper-custom-numbers-and-pagination{
    @include fluid-type(width, 320px, 464px, 128px, 184px);

    .swiper-numbers {
      @include fluid-type(font-size, 320px, 464px, 14px, 18px);
      @include fluid-type(width, 320px, 464px, 24px, 32px);
      @include fluid-type(height, 320px, 464px, 24px, 32px);
    }
  }
}

@media (max-width:320px) {
  $offering-content-area-320: 272px;
  $offering-content-area-320-padding: 0px;

  .landing-offerings {
    .landing-offerings-container{
      .landing-offerings-spacing-container{
        padding: 24px 0px;

        .landing-offerings-title {
          width: $offering-content-area-320;
          padding: 0px $offering-content-area-320-padding;
          margin: 0 auto 16px auto;
        }
      }
    }
  }

  // Carousel styling
  #landing-offerings-carousel{
    .landing-offerings-carousel-slide{
      width: $offering-content-area-320;
      padding: 0 0 0 $offering-content-area-320-padding;
      gap: 16px;

      .landing-offerings-carousel-slide-left{
        width: 200px;
        min-width: 200px;
        padding: 16px 0px;
    
        .landing-offerings-carousel-slide-left-body-container {
          gap: 24px;

          .landing-offerings-carousel-slide-left-text-container{
            gap: 16px;
      
            .landing-offerings-carousel-slide-left-title {
              font-size: 18px;
              line-height: 24px;
            }
      
            .landing-offerings-carousel-slide-left-body {
              font-size: 10px;
              line-height: 16px;
            }
          }

          .landing-offerings-carousel-slide-left-call-to-action {
            // width: 88px;
            height: 28px;
            font-size: 10px;
            line-height: 16px;
          }
        }
      } 
    
      .landing-offerings-carousel-slide-right-image-container {
        width: 128px;
        border-radius: 20px 0 0 20px;
      }
    } 
  }

  // Custom Pagination
  .swiper-custom-numbers-and-pagination{
    width: 128px;

    .swiper-numbers {
      font-size: 14px;
      width: 24px;
      height: 24px;
    }
  }
}

// ***************************
// who
// ***************************
@media (max-width: 1023px) {
  .landing-who {
    .landing-who-container {
      .landing-who-content-area {
        padding: 32px;
        margin-left: 0;
        margin-right: auto;

        .landing-who-text-container {
          padding-top: 30px;
          padding-bottom: 30px;
          padding-left: 30px;
          padding-right: 30px;
          height: auto;
          @include fluid-type(width, 720px, 1023px, 580px, 820px);

          .landing-who-body-container {
            padding-top: 0;
            padding-bottom: 0;

            .landing-who-text {
              @include fluid-type(font-size, 720px, 1023px, 18px, 21px);
              font-family: 'DM Sans', Helvetica, Arial, serif;

              .landing-who-text-bold {
                font-weight: 700;
                font-family: 'DM Sans', Helvetica, Arial, serif;
              }
            }
            .landing-who-btn-container {
              .landing-who-btn-pretext {
                @include fluid-type(font-size, 720px, 1023px, 18px, 21px);
              }
              .landing-who--join-our-team {
                font-weight: 500;
                font-size: 16px;
                border-radius: 0.5rem;
                @include fluid-type(padding-left, 720px, 1023px, 14px, 19px);
                @include fluid-type(padding-right, 720px, 1023px, 14px, 19px);
                @include fluid-type(padding-top, 720px, 1023px, 10px, 14px);
                @include fluid-type(padding-bottom, 720px, 1023px, 10px, 14px);
                border: 2px solid $landing-blue;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .landing-who {
    height: fit-content;
    .landing-who-container {
      .landing-who-content-area {
        padding: 32px;
        height: fit-content;
        width: auto;
        max-width: fit-content;
        // margin-left: 0;
        // margin-right: auto;

        .landing-who-text-container {
          background-color: $white;
          padding: 0;
          width: 100%;
          // @include fluid-type(padding-bottom, 320px, 720px, 24px, 48px);
          // @include fluid-type(padding-left, 320px, 720px, 6px, 24px);
          // @include fluid-type(padding-right, 320px, 720px, 6px, 24px);
          height: auto;
          // @include fluid-type(width, 320px, 720px, 257px, 560px);
          .landing-section-title {
            margin: 16px 10px 21px 10px;
          }
          .landing-who-body-container {
            padding: 0;
            margin: 0;
            height: fit-content;

            .landing-who-text {
              display: none;
            }
            .landing-who-text-mobile {
              display: flex;
              flex-direction: column;
              padding: 0px 19px;

              // padding: 0;
              @include fluid-type(font-size, 320px, 720px, 12px, 18px);
              font-family: 'DM Sans', Helvetica, Arial, serif;
              margin-bottom: 30px;

              .mobile-text-holder {
                height: fit-content;
                line-height: 1.5;
              }
              section {
                margin: 0;
              }
              span {
                display: inline-block;
                font-weight: 700;
              }
              .landing-who-text-highlight {
                color: $landing-blue;
              }
              .landing-who-btn-container {
                margin-top: 3.429rem;
                display: flex;
                margin-bottom: 0;
                height: 2.857rem;
                a {
                  height: fit-content;
                  @include fluid-type(font-size, 320px, 720px, 12px, 16px);
                }
              }
              .landing-who-text-bold {
                font-family: 'DM Sans', Helvetica, Arial, serif;
              }
            }
            .landing-who-btn-container {
              .landing-who-btn-pretext {
                @include fluid-type(font-size, 320px, 720px, 14px, 18px);
              }
              .landing-who-join-our-team {
                font-weight: 500;
                @include fluid-type(font-size, 320px, 720px, 14px, 16px);
                border-radius: 0.5rem;
                padding: 10px 1rem;
                border: 2px solid $landing-blue;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .landing-who {
    .landing-who-container {
      .landing-who-content-area {
        .landing-who-text-container {
          // height: auto;

          .landing-who-body-container {
            // padding: 0 12px;

            .landing-who-text {
              font-size: 14px;
              font-family: 'DM Sans', Helvetica, Arial, serif;
              margin-bottom: 24px;

              .landing-who-text-bold {
                font-weight: 700;
                font-family: 'DM Sans', Helvetica, Arial, serif;
              }
            }
            .landing-who-btn-container {
              .landing-who-btn-pretext {
                font-size: 14px;
              }
              .landing-who-join-our-team {
                font-family: 'DM Sans', Helvetica, Arial, serif;
                font-weight: 500;
                font-size: 14px;
                border-radius: 0.5rem;
                padding: 0.5rem 1rem;
                border: 2px solid $landing-blue;
              }
            }
          }
        }
      }
    }
  }
}

// ***************************
// News & Media
// ***************************
@media (max-width: 1023px) {
  .landing-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    height: fit-content;

    .landing-media-container {
      padding: 0;
      margin: 0;
      height: fit-content;
      width: 100%;
      max-width: 100%;
      .landing-media-table {
        width: 100%;
        padding: 0;
        height: fit-content;
        .landing-media-table-body {
          .pagination-container {
            margin-top: 40px;
          }

          .landing-media-table-row {
            .landing-media-table-text {
              height: auto;
              padding: 0;

              .landing-media-item {
                @include fluid-type(margin-top, 720px, 1023px, 14px, 18px);
                @include fluid-type(margin-bottom, 720px, 1023px, 14px, 18px);

                .landing-media-item-date {
                  @include fluid-type(font-size, 720px, 1023px, 12px, 14px);
                }
                .landing-media-item-title {
                  @include fluid-type(font-size, 720px, 1023px, 18px, 21px);
                }
                .landing-media-item-from {
                  @include fluid-type(font-size, 720px, 1023px, 12px, 14px);
                }
              }
            }

            .landing-media-table-img {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .landing-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    height: fit-content;

    .landing-media-container {
      padding: 0;
      margin: 0;
      height: fit-content;
      width: 100%;
      max-width: 100%;
      .landing-media-table {
        width: 100%;
        margin: 0;
        height: fit-content;

        .landing-media-table-body {
          .pagination-container {
            .pagination-div,
            .pagination-link {
              @include fluid-type(width, 320px, 550px, 2rem, 3.429rem);
              @include fluid-type(height, 320px, 550px, 2rem, 3.429rem);
            }
          }
          .news-button-holder {
            .pagination-div {
              @include fluid-type(width, 320px, 550px, 2rem, 3.429rem);
              @include fluid-type(height, 320px, 550px, 2rem, 3.429rem);

              a {
                width: 100%;
                height: 100%;
              }
            }
          }
          .landing-media-table-row {
            .landing-media-table-text {
              height: auto;
              padding: 0;

              .landing-media-item {
                margin-top: 14px;
                margin-bottom: 14px;

                .landing-media-item-date {
                  @include fluid-type(font-size, 320px, 720px, 11px, 12px);
                }
                .landing-media-item-title {
                  @include fluid-type(font-size, 320px, 720px, 14px, 18px);
                }
                .landing-media-item-from {
                  @include fluid-type(font-size, 320px, 720px, 11px, 12px);
                }
              }
            }

            .landing-media-table-img {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .landing-media-table-body {
    .news-event-holder {
      min-height: 530px;
    }
  }
}

@media (max-width: 320px) {
  .landing-media {
    .landing-media-container {
      .landing-media-table {
        width: 100%;
        .landing-media-table-body {
          .landing-media-table-row {
            .landing-media-table-text {
              height: auto;
              padding: 0;

              .landing-media-item {
                margin-top: 14px;
                margin-bottom: 14px;

                .landing-media-item-date {
                  font-size: 11px;
                }
                .landing-media-item-title {
                  font-size: 14px;
                }
                .landing-media-item-from {
                  font-size: 11px;
                }
              }
            }

            .landing-media-table-img {
              display: none;
            }
          }
        }
      }
    }
  }
}

// ***************************
// Call to Action
// ***************************
@media (max-width: 1023px) {
  .landing-action {
    .landing-action-btn-container {
      .landing-action-request-demo {
        @include fluid-type(font-size, 720px, 1023px, 18px, 21px);
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
}

@media (max-width: 720px) {
  .landing-action {
    @include fluid-type(padding-top, 320px, 720px, 14px, 48px);
    @include fluid-type(padding-bottom, 320px, 720px, 14px, 48px);

    .landing-action-btn-container {
      .landing-action-request-demo {
        @include fluid-type(font-size, 320px, 720px, 12px, 18px);
        @include fluid-type(padding-top, 320px, 720px, 7px, 14px);
        @include fluid-type(padding-bottom, 320px, 720px, 7px, 14px);
        @include fluid-type(padding-left, 320px, 720px, 28px, 48px);
        @include fluid-type(padding-right, 320px, 720px, 28px, 48px);
      }
    }
  }
}

@media (max-width: 320px) {
  .landing-action {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .landing-action-btn-container {
      .landing-action-request-demo {
        font-size: 12px;
        padding: 0.5rem 2rem;
      }
    }
  }
}

// ***************************
// Footer
// ***************************
@media (max-width: 1023px) {
  .landing-footer {
    @include fluid-type(padding-left, 720px, 1023px, 4.5rem, 8rem);
    @include fluid-type(padding-left, 720px, 1023px, 4.5rem, 8rem);

    .landing-footer-top-section {
      padding: 24px 0 32px;

      .logo {
        .logo-text {
          @include fluid-type(font-size, 720px, 1023px, 32px, 36px);
          padding-left: 1rem;
        }
      }
    }

    .landing-footer-mid-section {
      padding-bottom: 2rem;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      .landing-footer-table-of-contents-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;

        .landing-footer-table-of-contents-content-container {
          width: 100%;

          .landing-footer-table-of-contents {
            .landing-footer-table-of-contents-link {
              @include fluid-type(font-size, 720px, 1023px, 16px, 18px);
              width: 45%;
              margin-right: 5%;
              margin-bottom: 1rem;

              &:nth-child(2n) {
                margin-right: 0;
              }
            }
          }
        }
      }

      .landing-footer-support-container {
        width: 50%;
        margin-bottom: 2rem;

        .landing-footer-support {
          .landing-footer-support-link {
            @include fluid-type(font-size, 720px, 1023px, 16px, 18px);
            margin-bottom: 1rem;

            .best-viewing-experience {
              line-height: 1.5;
            }
          }
        }
      }

      .landing-footer-contact-us-container {
        width: 50%;

        .landing-footer-contact-us {
          .landing-footer-contact-us-link {
            @include fluid-type(font-size, 720px, 1023px, 16px, 18px);
            margin-bottom: 1rem;
          }
        }
      }
    }

    .landing-footer-bottom-section {
      .landing-footer-bottom-section-line {
        height: 2px;
        margin-bottom: 1.5rem;
      }

      .landing-footer-bottom-section-ArbiLex-social-container {
        margin-bottom: 10px;

        .landing-footer-bottom-section-ArbiLex-social {
          margin-left: 0;
          width: auto;
          margin: auto;

          .landing-footer-bottom-section-ArbiLex-social-item {
            margin-left: 1.5rem;

            svg {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      .landing-footer-bottom-section-text {
        padding-bottom: 2rem;
        @include fluid-type(font-size, 720px, 1023px, 16px, 18px);
      }
    }
  }
}

@media (max-width: 720px) {
  .landing-footer {
    padding: 0 62px;
    @include fluid-type(padding-left, 320px, 720px, 30px, 62px);
    @include fluid-type(padding-right, 320px, 720px, 30px, 62px);

    .landing-footer-top-section {
      padding: 24px 0 32px;

      .logo {
        .logoMark {
          @include fluid-type(height, 320px, 720px, 25px, 36px);
        }
        .logo-text {
          @include fluid-type(font-size, 320px, 720px, 21px, 32px);
          padding-left: 1rem;
        }
      }
    }

    .landing-footer-mid-section {
      padding-bottom: 2rem;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      .landing-footer-table-of-contents-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;

        .landing-footer-table-of-contents-content-container {
          width: 100%;

          .landing-footer-table-of-contents {
            .landing-footer-table-of-contents-link {
              @include fluid-type(font-size, 320px, 720px, 14px, 16px);
              width: 45%;
              margin-right: 5%;
              margin-bottom: 1rem;

              &:nth-child(2n) {
                margin-right: 0;
              }
            }
          }
        }
      }

      .landing-footer-support-container {
        width: 50%;
        margin-bottom: 2rem;

        .landing-footer-support {
          .landing-footer-support-link {
            @include fluid-type(font-size, 320px, 720px, 14px, 16px);
            margin-bottom: 1rem;

            .best-viewing-experience {
              line-height: 1.5;
            }
          }
        }
      }

      .landing-footer-contact-us-container {
        width: 50%;

        .landing-footer-contact-us {
          .landing-footer-contact-us-link {
            @include fluid-type(font-size, 320px, 720px, 14px, 16px);
            margin-bottom: 1rem;
          }
        }
      }
    }

    .landing-footer-bottom-section {
      .landing-footer-bottom-section-line {
        height: 2px;
        margin-bottom: 1.5rem;
      }

      .landing-footer-bottom-section-ArbiLex-social-container {
        margin-bottom: 10px;

        .landing-footer-bottom-section-ArbiLex-social {
          margin-left: 0;
          width: auto;
          margin: auto;

          .landing-footer-bottom-section-ArbiLex-social-item {
            margin-left: 1.5rem;

            svg {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      .landing-footer-bottom-section-text {
        padding-bottom: 2rem;
        @include fluid-type(font-size, 320px, 720px, 14px, 16px);
      }
    }
  }
}

@media (max-width: 320px) {
  .landing-footer {
    padding: 0 30px;

    .landing-footer-top-section {
      padding: 24px 0 32px;

      .logo {
        .logoMark {
          height: 25px;
        }
        .logo-text {
          font-size: 21px;
          padding-left: 1rem;
        }
      }
    }

    .landing-footer-mid-section {
      padding-bottom: 2rem;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      .landing-footer-table-of-contents-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;

        .landing-footer-table-of-contents-title {
        }

        .landing-footer-table-of-contents-content-container {
          width: 100%;

          .landing-footer-table-of-contents {
            .landing-footer-table-of-contents-link {
              font-size: 12px;
              width: 45%;
              margin-right: 10%;
              margin-bottom: 1rem;

              &:nth-child(2n) {
                margin-right: 0;
              }
            }
          }
        }
      }

      .landing-footer-support-container {
        width: 100%;
        margin-bottom: 2rem;

        .landing-footer-support {
          .landing-footer-support-link {
            font-size: 12px;
            margin-bottom: 1rem;
          }
        }
      }

      .landing-footer-contact-us-container {
        width: 100%;

        .landing-footer-contact-us {
          .landing-footer-contact-us-link {
            font-size: 12px;
            margin-bottom: 1rem;
          }
        }
      }
    }

    .landing-footer-bottom-section {
      .landing-footer-bottom-section-line {
        height: 2px;
        margin-bottom: 1.5rem;
      }

      .landing-footer-bottom-section-ArbiLex-social-container {
        margin-bottom: 10px;

        .landing-footer-bottom-section-ArbiLex-social {
          margin-left: 0;
          width: auto;
          margin: auto;

          .landing-footer-bottom-section-ArbiLex-social-item {
            margin-left: 1.5rem;

            svg {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      .landing-footer-bottom-section-text {
        padding-bottom: 2rem;
        font-size: 12px;
      }
    }
  }
}

// ***************************
// Generics
// ***************************
@media (max-width: 1023px) {
  .landing-section-title {
    @include fluid-type(font-size, 720px, 1023px, 16px, 18px);
    margin-bottom: 2rem;
  }

  .landing-footer-title {
    @include fluid-type(font-size, 720px, 1023px, 14px, 16px);
  }
}

@media (max-width: 720px) {
  .landing-section-title {
    @include fluid-type(font-size, 320px, 720px, 12px, 16px);
    @include fluid-type(margin-bottom, 320px, 720px, 1.5rem, 2rem);
  }

  .landing-footer-title {
    @include fluid-type(font-size, 320px, 720px, 12px, 14px);
  }
}

@media (max-width: 320px) {
  .landing-section-title {
    font-size: 12px;
    margin-bottom: 1.5rem;
  }

  .landing-footer-title {
    font-size: 12px;
  }
}
