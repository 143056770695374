.client-billing-modal-wrapper {
  z-index: $z-index-20000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.3);

  .client-billing-modal {
    z-index: $z-index-20001;
    position: relative;
    left: 50%;
    top: 50%;
    width: 320px;
    margin-left: -160px;
    margin-top: -110px;
    background-color: white;
    font-size: 16px;
    border-radius: 5px;

    .close-modal-button {
      position: absolute;
      right: 12px;
      top: 0px;
      cursor: pointer;
      font-family: 'DM Sans', Helvetica, Arial, serif;
      font-size: 1.5rem;
    }

    .client-billing-modal-content {
      padding: 30px;

      .client-billing-modal-form {
        text-align: right;
        p {
          text-align: left;
          font-family: "DM Sans", Helvetica, Arial, serif;
          font-weight: 500;
          
          :empty {
            display: none;
          }
        }
      }
      .client-billing-submit-button {
        padding: .75rem 1rem;
        color: white;
        background-color: #2f5bea;
        cursor: pointer;
        border-radius: 5px;
      }
    }
  }
}

@media (max-width: 700px) {
  .client-billing-modal-form {
    @include fluid-type(font-size, 340px, 720px, 14px, 18px);

    input {
      font-size: inherit;
    }
    textarea {
      font-size: inherit;
    }
    select {
      font-size: inherit;
    }
  }
}
